import { useAppConfig } from '@rugby-au/app-config';
import { addEncodedParams, create, getItem, getProfile, recover, useFetch } from '@rugby-au/services';
import { obfuscateEmail } from '@rugby-au/commons';
import { FormConfigProps, FormRefProps } from '@rugby-au/form';
import { IconTypes } from '@rugby-au/icons';
import { MultiFormLayoutProps, useMultiFormProvider } from '@rugby-au/multi-form';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { addNational } from '../../utils';
import { AccountDetails } from './AccountDetails';
import { accountDetailsFormConfig } from './accountDetailsFormConfig';
import { useRouter } from 'next/router';
import { ActivityIndicator, View } from 'react-native';

export const AccountDetailsLayout = ({ layoutRef, layoutKey, triggerNext }: MultiFormLayoutProps) => {
  const { setMultiFormData, multiFormData } = useMultiFormProvider();
  const [isNationalsLoading, setIsNationalsLoading] = useState(true);
  const params = useRouter();

  useEffect(() => {
    if (multiFormData.__meta?.existingUser) {
      triggerNext && triggerNext();
    }
  }, [multiFormData.__meta?.existingUser, triggerNext]);

  const { nationalConfig, nationalId, nationals } = useAppConfig();

  const _accountDetailsFormConfig = useMemo(() => {
    const htmlLabel = `I consent to the personal information I provide in connection with my sign up to and use of Rugby Xplorer to be collected and handled in accordance with the <a style='color: blue; text-decoration: underline;' target='_blank' href='${nationalConfig?.config?.general?.privacyPolicy?.link}'>${nationalConfig?.config?.general?.privacyPolicy?.name}</a> which I have read.`;
    const dynamicFields: { [key: string]: any } = {};
    const items = nationals?.map((national: any) => {
      dynamicFields[national._id] = {
        key: `${national._id}PrivacyPolicy`,
        htmlLabel,
        required: true,
        displayType: 'Checkbox' as const,
        displayValidation: true,
        dependsOnFields: ['nationals'],
      };
      return {
        value: { [national._id]: { name: national.name } },
        label: national.name,
      };
    });

    setIsNationalsLoading(false);

    const fildsExtraValidation: { [key: string]: any } = {
      email: async (value: string) => {
        const result = await getProfile({ email: value, nationalId }, 'email');
        if (!result || result.error) {
          return {
            isValid: false,
            error: result?.message,
          };
        }

        const redirectToLogin = addEncodedParams('/login', params.query);
        return {
          isValid: result.length <= 0,
          additionalMessage: {
            htmlLabel: `A Rugby account already exists with this email address. Please <a style="color: blue; text-decoration: underline;" href="${redirectToLogin}">Login</a> to continue.`,
          },
        };
      },
    };

    return {
      ...accountDetailsFormConfig,
      fields: [
        ...accountDetailsFormConfig.fields.map((field: any) => {
          const _field = { ...field };
          if (_field.key === 'nationals') {
            _field.items = items ?? [];
          }
          if (_field.addExtraValidation) {
            _field.extraValidation = fildsExtraValidation[_field.key];
          }

          //TODO need a better way, just for now
          if (nationalConfig?.config?.general?.locale && _field.key === 'mobile') {
            _field.defaultCountry = nationalConfig.config.general.locale.toUpperCase();
          }
          if (nationalId && _field.key === 'nationals') {
            const _default = _field.items.find((item: any) => item.value[nationalId] !== undefined);
            if (_default) {
              _field.value = _default.value;
            }
          }
          if (_field.key === 'rxNote') {
            if (!nationalId) {
              // Default text when 'nationalId' is null
              _field.label =
                'Please ensure you have selected the correct Rugby National above. By signing up to and using Rugby Xplorer, you will be providing personal information (including sensitive information) to both Rugby Xplorer and your selected Rugby National, which will each collect, store, and use such information in accordance with their respective privacy policies.';
            } else {
              _field.label = _field.label.replace('{{NationalName}}', nationals?.find(n => n._id === nationalId)?.name);
            }
          }
          // Set displayDateFormat based on nationalId
          if (_field.key === 'birthdate') {
            _field.displayDateFormat = !!nationalId;
          }
          return _field;
        }),
        ...Object.values(dynamicFields),
      ],
    } as FormConfigProps;
  }, [nationalConfig, nationals, nationalId, params.query]);

  const [isExistingModalVisible, setIsExistingModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [existingUsers, setExistingUsers] = useState([]);
  const [error, setError] = useState<string | null>(null);
  const formRef = useRef<FormRefProps>(null);
  const { setNotification } = useAppConfig();
  const [submitedData, setSubmitedData] = useState<any>({});
  const { fetchRequest } = useFetch({});
  const cleanSubmitData = (data: any, _region: any) => {
    const query = params.query;
    const aditionalQueryParams: { [key: string]: any } = {};
    for (const key in query) {
      if (Object.prototype.hasOwnProperty.call(query, key) && !['clientId', 'codeChallenge', 'redirectPath'].includes(key)) {
        aditionalQueryParams[key] = encodeURIComponent(query[key] as string);
      }
    }
    return { ...data, aditionalQueryParams, isLinkAccount: false, nationalId };
  };

  useEffect(() => {
    const beforeNext = async () => {
      let result = await formRef.current?.onSubmit();
      setMultiFormData((prev: any) => {
        return { ...prev, [layoutKey]: { ...result } };
      });
      if (result?.isValid && result && result.response.length > 0) {
        setIsExistingModalVisible(true);
        setSubmitedData(result.submitData);
        // console.log('AccountDetailsLayout.tsx line 67 - result.submitData ', result.submitData);
        let _existingUSers = result.response;
        _existingUSers = _existingUSers.map((item: any) => {
          return { ...item, emailMasked: `${obfuscateEmail(item.email)}` };
        });
        setExistingUsers(_existingUSers);
        return { isValid: false };
      } else if (result?.isValid) {
        const client = await getItem('client');
        client.username = params.query?.clientId ?? client.username;
        client.codeChallenge = params.query?.codeChallenge ?? client.codeChallenge;
        client.redirectPath = params.query?.redirectPath;
        const account: any = await addNational({ obj: result?.submitData, nationalId });
        const _result = await fetchRequest(create, { account, client, nationalId });
        return { isValid: _result?.status === 'Success' };
      }
      return { isValid: false };
    };

    const beforeBack = async () => {
      return { isValid: true };
    };

    if (layoutRef) {
      layoutRef.current = {
        beforeNext,
        beforeBack,
      };
    }
  }, [params, layoutKey, layoutRef, setMultiFormData, fetchRequest, nationalId]);

  const cardItemListFooter = {
    onPress: async () => {
      setIsLoading(true);
      try {
        setMultiFormData((prev: any) => {
          const current = prev[layoutKey];
          current.userType = 'new';
          return { ...prev, [layoutKey]: current };
        });
        const account: any = multiFormData[layoutKey].submitData;
        const client = await getItem('client');
        client.username = params.query?.clientId ?? client.username;
        client.codeChallenge = params.query?.codeChallenge ?? client.codeChallenge;
        const result = await fetchRequest(create, { account, client, nationalId });
        // account.origin = appName;
        if (result.status === 'Success') {
          setIsLoading(false);
          if (triggerNext) {
            await triggerNext();
          }
        } else {
          setError(result.error.message);
          // setNotification({ message: result.error.message, severity: 'error' });
          setIsLoading(false);
        }
      } catch (e) {
        console.error('AccountDetailsLayout.tsx line 183 - error ', e);
        setIsLoading(false);
      }
    },
    iconLeft: 'addAccount' as IconTypes,
    iconRight: 'arrowRight' as IconTypes,
  };
  const cardContentListFooter = {
    title: 'Continue with a new profile',
  };
  const onExistingUserPress = async (item: any) => {
    // if (item.from_crm) {
    // It does not matter if the user already exists on RX or if is comming from CRM always will be a recover
    item.signupBirthdate = submitedData.birthdate;
    item.signupMobile = submitedData.mobile;
    item.signupEmail = submitedData.email;
    item.clientId = params.query?.clientId;
    item.codeChallenge = params.query?.codeChallenge;
    // item.redirectUrl = params.query.redirectUrl;
    item.redirectPath = params.query?.redirectPath;
    const response = await fetchRequest(recover, {
      account: item,
      nationalId,
      type: 'email',
    });
    if (response.error) {
      setNotification({ message: response.error.message, severity: 'error' });
      return;
    }
    // }
    setMultiFormData((prev: { [key: string]: any }) => {
      return { ...prev, __meta: { ...prev?.__meta, existingUser: item } };
    });
  };

  return isNationalsLoading ? (
    <View style={{ position: 'absolute' }}>
      <ActivityIndicator size={'small'} />
    </View>
  ) : (
    <AccountDetails
      formRef={formRef}
      accountDetailsFormConfig={_accountDetailsFormConfig}
      isExistingModalVisible={isExistingModalVisible}
      setIsExistingModalVisible={setIsExistingModalVisible}
      isLoading={isLoading}
      error={error}
      existingUsers={existingUsers.map((item: any) => {
        return {
          ...item,
          title: `${item.firstname ?? ''} ${item.lastname ?? ''}`,
          // description: [`Rugby ID: ${item.myrugby_id ?? item?.meta?.externalIds?.myrugby_id ?? item.national[0].crm_id ?? ''}`, `Email: ${item.emailMasked ?? ''}`],
          description: [`Rugby ID: ${item.nationals[nationalId as string].myrugby_id ?? ''}`, `Email: ${item.emailMasked ?? ''}`],
          info: `Age: ${item.age ?? ''}`,
        };
      })}
      cardItemListFooter={cardItemListFooter}
      cardContentListFooter={cardContentListFooter}
      cleanSubmitData={cleanSubmitData}
      onExistingUserPress={onExistingUserPress}
    />
  );
};
