import { FormConfigProps } from '@rugby-au/form';

export const accountDetailsFormConfig: FormConfigProps = {
  meta: {
    name: 'Sign Up',
    title: 'Sign Up',
    submitPath: 'rau/auth/v2/profile/?type=fullsearch',
    submitMethod: 'GET',
    requestParams: { encodedParams: ['firstname', 'lastname', 'mobile', 'email', 'birthdate'] },
  },
  fields: [
    {
      key: 'nationals',
      displayType: 'PickerInput' as const,
      label: 'Rugby National',
      items: [],
      value: null,
      required: true,
      onUpdateAction: { type: 'appConfigRegion', key: 'nationalId', valueKey: 'objectKey', keyIndex: 0 },
      placeholder: 'Please select your Rugby National',
    },
    { key: 'firstname', label: 'First Name', required: true, displayType: 'TextInput' as const, customInputStyles: { textTransform: 'capitalize' } /*, value: 'Diego' */ },
    { key: 'lastname', label: 'Last Name', required: true, displayType: 'TextInput' as const, customInputStyles: { textTransform: 'capitalize' } /*, value: 'Torres' */ },
    {
      key: 'birthdate',
      label: 'Date of birth',
      required: true,
      displayDateFormat: false,
      displayType: 'DateInput' as const,
      autoValidate: true,
      autoValidateTime: 0,
      maxYears: 13,
      maxYearsError:
        'Individuals must be at least 13 years old to hold a Rugby Xplorer primary account. Please ensure your parent/guardian creates a primary account for themselves, before creating and linking your account.',
      minYears: 120,
      minYearsError: 'Please check your DOB. Individuals must be at most 120 years old.',
    },
    { key: 'mobile', label: 'Phone Number', required: true, displayType: 'PhoneInput' as const, autoCompleteTypeWeb: 'on' /*, value: '0406304731' */ },
    {
      key: 'email',
      type: 'email-address',
      label: 'Email Address',
      required: true,
      displayType: 'TextInput' as const,
      //   autoValidate: true,
      addExtraValidation: true,
      customInputStyles: { textTransform: 'lowercase' },
      // value: 'xxxxx.xxxxx@rugby.com.au',
    },
    {
      key: 'rxNote',
      label:
        'Please note that by signing up to and using Rugby Xplorer you will be providing personal information (including sensitive information) to both Rugby Xplorer and {{NationalName}} which will each collect, store and use such information in accordance with the respective privacy policies linked below.',
      displayType: 'LabelField' as const,
    },
    {
      key: 'rxPrivacyPolicy',
      htmlLabel:
        'I consent to the personal information I provide in connection with my sign up to and use of Rugby Xplorer to be collected and handled in accordance with the <a style="color: blue; text-decoration: underline;"  target="_blank" href="https://xplorer.rugby/privacy-policy">Rugby Xplorer Privacy Policy</a> which I have read.',
      required: true,
      displayType: 'Checkbox' as const,
      dependsOnFields: ['nationals'],
      // value: true,
    },
  ],
};
